import React, {useState} from "react";
import {Link} from "react-router-dom";
import MegaMenu from "./mega-menu/MegaMenu";
import MegaMenuMobile from "./mega-menu/MegaMenuMobile";
import {useTranslation} from 'react-i18next';

const HeaderThree = () => {
    const [navbar, setNavbar] = useState(false);
    const {i18n} = useTranslation();

    const changeBackground = () => {
        if (window.scrollY >= 68) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };

    const changeLanguage = (lng) => {
        const currentUrl = window.location.href;
        const newUrl = new URL(currentUrl);
        newUrl.searchParams.set('lng', lng);
        window.location.href = newUrl.toString();
    };
    console.log(i18n.language);

    window.addEventListener("scroll", changeBackground);

    return (
        <>
            <div
                className={
                    navbar
                        ? "theme-main-menu sticky-menu theme-menu-two bg-none fixed"
                        : "theme-main-menu sticky-menu theme-menu-two bg-none"
                }
            >
                <div className="d-flex align-items-center justify-content-center">
                    <div className="logo">
                        <Link to="/">
                            <img src="/images/logo/main-logo.png" alt="brand logo"/>
                        </Link>
                    </div>
                    {/* End Logo */}

                    <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
                        <div className="container nav-container">
                            <div
                                className="navbar-collapse collapse"
                                id="navbarSupportedContent"
                            >
                                <div className="d-lg-flex justify-content-between align-items-center">
                                    <MegaMenu/>
                                    {/* End MegaMenu */}

                                    <ul className="right-widget">
                                        <li className="d-sm-flex">
                                            <ul className="language-button-group d-flex align-items-center justify-content-center">
                                                <li>
                                                    <button
                                                        className={`ru-lang ${i18n.language === 'ua' ? 'active' : ''}`}
                                                        onClick={() => changeLanguage('uk')}>
                                                        Ua{" "}
                                                    </button>
                                                </li>
                                                <li>
                                                    <a href="/en/" className="eng-lang"
                                                       onClick={() => changeLanguage('en')}>
                                                        En{" "}
                                                    </a>
                                                </li>
                                                <li>
                                                    <button onClick={() => changeLanguage('pl')}
                                                            className={`pl-lang ${i18n.language === 'pl' ? 'active' : ''}`}
                                                    >
                                                        Pl{" "}
                                                    </button>
                                                </li>
                                            </ul>
                                            {/* End ul */}

                                        </li>
                                    </ul>
                                    {/* End right-button-group  */}
                                </div>
                            </div>
                        </div>
                    </nav>
                    {/* End nav */}
                </div>

                <MegaMenuMobile/>
                {/* 	End Mega Menu for Mobile */}
            </div>
            {/* /.theme-main-menu */}
        </>
    );
};

export default HeaderThree;
