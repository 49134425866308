import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import Routes from "./router/Routes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from 'react-i18next';
import './i18n';

const App = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
    }, []);
    window.addEventListener("load", AOS.refresh);

    const changeLanguage = (lng) => {
        const currentUrl = window.location.href;
        const newUrl = new URL(currentUrl);
        newUrl.searchParams.set('lng', lng);
        window.location.href = newUrl.toString();
    };

    return (
        <>
            <Helmet>
                <title>{t('welcome')}CRM Genesis - комплексне впровадження CRM-систем на базі Creatio</title>
                <meta property="og:site_name" content="CRM Genesis"/>
                <meta property="og:type" content="website"/>
                <meta
                    property="og:title"
                    content="CRM Genesis - комплексне впровадження CRM-систем на базі Creatio"
                />
                <meta
                    name="description"
                    content="CRM Genesis - Ваш партнер в оптимізації бізнес-процесів. Широкий спектр послуг: ✔автоматизація бізнес-процесів ✔консалтинг ✔впровадження CRM ✔консалтинг ✔навчання. Індивідуальний підхід для розвитку вашого бізнесу"
                />
            </Helmet>
            {/* End Seo Helmt */}

            <ScrollToTop/>

            <Routes/>
        </>
    );
};

export default App;
