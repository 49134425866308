import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
        translation: {
            "welcome": "Welcome to CRM Genesis",
            // другие переводы
        }
    },
    pl: {
        translation: {
            "welcome": "Witamy w CRM Genesis",
            // другие переводы
        }
    },
    uk: {
        translation: {
            "welcome": "Добрий день в CRM Genesis",
            // другие переводы
        }
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'uk',
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'path', 'subdomain'],
            caches: ['cookie']
        },
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;